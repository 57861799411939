*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    user-select: none;        

}


@keyframes progress {
    0% { width: 0%; }
    100% { width: 100%; }
}
  
/* Style the loading screen */
#loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 9999;
    opacity: 1;
    transition: opacity .8s ease-out;
}
  
  /* Style the loading bar */
#loading-bar {
    position: absolute;
    top: 50%;
    left: 0;
    height: 5px;
    background-color: white;
    animation: progress 3s ease-out forwards;
}
  
  /* Style the loading text */
#loading-text {
    position: absolute;
    top: calc(50% - 80px);
    left: 0;
    width: 100%;
    font-family: 'Plus Jakarta Display', sans-serif;;
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

/* loader ellipse animation */
.lds-ellipsis {
    display: inline-block;
    position: absolute;
    top: calc(50% + 20px);
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes flowing {
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    80% { transform: translateX(5px); }
    100% { transform: translateX(0); }
  }
  
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}










.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
.section
{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    height:8vh;
    width:100vw;
    position: absolute;
    font-family: 'Plus Jakarta Display', sans-serif;;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.2);
    
    font-size: 4vmin;
    padding: 0;
    border: 0;
    margin: 0;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16);

}
.section > div 
{
    margin-left: 5vw;
    margin-top: 2vh;
    cursor: pointer;
}




